.projects {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
}

.projects img {
    max-width: 95%;
}

