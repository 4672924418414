.navbar {
    position: fixed;
    background-color: #FEFAE0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    z-index: 1000;
}

.navbar a {
  color : #223F39;
  margin: 20px;
  text-decoration: none;
  cursor: pointer;
  font-size: 150%;
}

.navbar img {
  max-height: 90%;
  max-width: 100%;
}

.navbar button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #223F39;
}

.firstRow{
  display: flex;
  flex-direction: row;
}

h2#logo {
  font-weight: 400;
  font-size: 200%;
}

hr {
  color: #223F39;
  border: none;
  background-color: #223F39;
  height: 1px;
  width: 100%;
  margin: 0;
}

.leftSide {
    flex: 30%;
    height: 75px;
    display: flex;
    align-items: center;
    padding-left: 5%;
}

.rightSide {
    flex: 70%;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 5%;
}



.sideBar {
    display : none;
    background-color: #223F39;
    width: 100%;
    height: 50px;
    padding-left: 0;
    align-items: center;
}

.sideBar a {
    color: #FEFAE0;
    margin: auto;
}

@media only screen and (max-width: 750px) {
  .navbar .rightSide .shownLinks {
    display: none;
    width: 0;
  }

  .navbar a {
    font-size: 100%;
  }

  .navbar .rightSide .shownLinks a {
    font-size: 1px;
  }
  
  .navbar .rightSide {
    justify-content: flex-end;
    padding-right: 0px;
    width: 40%;
  }

  .navbar .leftSide h2{
    padding-left: 20px;
    width: 50%;
  }
    
  .navbar .leftSide a {
    display: none;
  }

  .navbar .sideBar.open {
    display: flex;
    color: white;
  }


  .navbar .rightSide button {
    display: inherit;
    padding-right: 50px;
  }
  }

  .navbar .leftSide img {
    display: inherit;
    max-width: 100%;
    height: auto;
  }

  .navbar .leftSide h2{
    display: none;
  }

@media only screen and (min-width: 750px) {
  .navbar .rightSide button {
    display: none;
  }

  .navbar .leftSide h2{
    display: inherit;
  }

  .navbar .leftSide img {
    display: none;
  }

  .navbar .sideBar.open{
    display: none;
  }

  .hiddenLinks {
    display: none;
  }

}