.App {
  text-align: center;
  font-family: Optima, Candara, 'Noto Sans', source-sans-pro, sans-serif;
  font-weight: 100;
  width: 100%;
  height: auto;
  min-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #FEFAE0;
  color: #223F39;
  margin: 0;
  padding: 0;
}

.page {
  display: flex;
}

.titleBox{
  text-align: left;
  width: 100%;
  margin: 5px 0 ;
}

.halfPage{
  width: 50%;
  display: flex;
}

.center{
  justify-content: center;
  margin: auto;
}

.centerVertical {
  justify-content: flex-start;
}

.halfPage img {
  max-height: 75vh;
  max-width: 100%;
}

.thirdPage {
  width: 32%;
  display: flex;
}

.container {
  height: auto;
  border: #223F39 0.1rem solid;
  border-radius: 3rem;
  justify-content: center;
  padding-bottom: 0.5rem;
}

.dark {
  background-color:#223F39;
  color: #FEFAE0;
}

.stackedContainers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 0.5rem;
}

.horizontalContainers {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.large .container img {
  max-height: 100%;
  max-width: 100%;
}

.green-midsize-button {
  width: 15vw;
  height: 8vh;
  background-color: #223F39;
  color: #FEFAE0;
  border-radius: 3rem;
  align-items: center;
  text-align: center;
  border: #12211e 0.1rem solid;
  padding: 1rem;
}

.leftText {
  text-align: left;
}

.rightText {
  max-width: 50%;
  text-align: right;
}

.largeLink {
  color: #FEFAE0;
  font-size: 30px;
}

button:hover {
  background-color: #12211e; /* Color on hover */
}

body {
  padding: 0;
  margin: 0;
  height: 100vh;
}

p {
  font-size: 1.3rem;
  font-weight: 50;
  margin: 1%;
} 

h1 {
  font-size: 6rem;
  font-weight: 400;
  margin: 0;
}

h2 {
  font-size: 5rem;
  font-weight: 300;
  margin: 0;
}

h3 {
  font-size: 2rem;
  font-weight: 550;
  margin: 0;
}

.topMargin {
  margin: 1rem 0;
}

.bottomMargin {
  margin: 0 0 1rem 0;
}

@media only screen and (min-width: 750px){
  .green-midsize-button {
    width: 15vw;
  }

  .section {
    width: 85vw;
    padding: 60px 0px; /* Add padding to compensate for the fixed nav */
    margin-top: 60px; /* Same height as nav to avoid overlap */
  }

  .halfPage {
    width: 50%;
}

  .container {
    width: 30vw;
  }

  .large {
    width: 100%;
  }

  .page { 
    flex-direction: row;
  }

  .small {
    width: 27vw;
  }

}

@media only screen and (max-width: 750px) {

  h2 {
    font-size: 3rem;
  }
  .green-midsize-button {
    width: 35vw;
  }

  .section {
    width: 100vw;
    height: auto;
    margin-top: 0;
    padding: 60px 0 0 0;
    align-items: center;
  }

  .halfPage {
    width: 100%;
}

  .stackedContainers{
    width: 100%;
  }

  .container {
    width: 100%;
  }
  
  .large {
    width: 100%;
  }

  .small {
    width: 100%
  }
  

  .page {
    padding: 0 2rem;
    flex-direction: column;
    gap: 1rem;
  }
  
  }