.experience {
    flex-wrap: wrap;
}

.stackedContainers  .experience {
    display: flex;
    flex-direction: column;
}

#workexp{
    padding: 2rem 1.5rem;
    display: block;
    flex-direction: row;
    box-sizing: border-box
}

.header {
    width: 100%;
}

.experience hr {
    background-color: #FEFAE0;
}
