.footer {
    min-height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1rem 0 ;
    background-color: #223F39;
}

.footer p {
    color: #FEFAE0;
  }

@media only screen and (max-width: 600px) {


}

@media only screen and (min-width: 600px) {
    
    
}