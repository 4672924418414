

.home {
    gap: 1rem;
    padding-top: 20px;
}


.homeRightSide{
    width: 50%;
    justify-content: center;
    align-items: center;
    place-content: center;
    overflow: hidden;
    max-height: 75%;
    
}

.homeRightSide img {
    max-width: 100%;  
    max-height: 85vh;
    display: block;
    margin: auto;
    align-items: center;
    border-radius: 3rem;
}

.homeLeftSide {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.homeLeftText {
    justify-content: left;
    text-align: left;
    padding: 0  1rem 0 0 ;
}

.buttonContainer{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 0  4rem 0 0;
}

button{
    font-family: Optima, Candara, 'Noto Sans', source-sans-pro, sans-serif;
}

.home p {
    margin: 2rem 0;
}

.home h3 {
    font-weight: 50;
}

.home .buttonContainer p{
    margin: 0;
}

@media only screen and (max-width: 750px) {

    .home {
        flex-direction: column;
    }

    .homeRightSide{
        width: 100%;
    }

    .homeLeftSide{
        width: 100%;
        padding: 0;
    }

    .buttonContainer{
        padding: 0;
    }
    


}